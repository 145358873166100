import { deleteAllCookies } from "@src/helpers/cookie";
import { useCallback } from "react";

/**
 * @returns handle to remove all authenticate cookie properties and navigate back home with a window reload
 */
export const useLogoutUser = () => {
  return useCallback((args?: { reload?: boolean }) => {
    // delete all cookies
    deleteAllCookies();
    // reload for good measure => will also reset user role without flickering
    // TODO We need to upgrade this whole flow. Login SHOULD NOT be dependent on the router or query params
    // should only use query params for initialization and not management
    if (args?.reload) {
      window.location.replace(window.location.pathname);
    }
  }, []);
};
