import React, { useMemo, useRef, useState, useEffect } from "react";
import isEqual from "lodash/isEqual";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import MuiButton from "@mui/material/Button";
import { styled, Theme } from "@mui/material/styles";
import { useAsyncFn } from "react-use";
import { useQueryClient } from "react-query";

import { Api } from "../../api/api";
import { QueryKeys } from "../../api/QueryKeys";
import { User } from "../../contracts/user/user";
import PictureUpload from "./PictureUpload";
import Button from "../buttons/Button";
import ConfirmationDialog from "../ConfirmationDialog";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLogoutUser } from "@src/providers/UserProvider";
import type { UserUpdate } from "@src/contracts/user/user";
import { splitFullName } from "@src/helpers/utils";
import { updateUiAvatarParams } from "@src/helpers/image";

interface ProfileTabProps {
  /**
   * The id of the event or networking hub
   */
  entityId: string;
  /**
   * The user object
   */
  user: User;

  /**
   * The variant to display for the compatibility checker
   */
  variant: "live" | "networking";
}

const Container = styled("div")(({ theme: { spacing, breakpoints } }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flex: 1,
  minHeight: 0,
  overflow: "auto",
  padding: spacing(1),
  paddingLeft: spacing(1.5),
  paddingRight: spacing(1.5),
  [breakpoints.down("sm")]: {
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
  },
}));

const PictureUploadContainer = styled("div")(({ theme: { spacing } }) => ({
  textAlign: "center",
  marginTop: spacing(1),
  marginBottom: spacing(1),
}));

const ProfileTab = ({ user, variant, entityId }: ProfileTabProps) => {
  const formattedName = useMemo(() => splitFullName(user.name), [user.name]);

  const initialRef = useRef({
    firstName: formattedName.firstName,
    lastName: formattedName.lastName,
    companyName: user.companyName || "",
    title: user.title || "",
    profilePicture: updateUiAvatarParams(user.profilePicture, user.name),
  });

  const [form, setForm] = useState(initialRef.current);
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);

  const logout = useLogoutUser();

  const queryClient = useQueryClient();

  const [saveState, onSave] = useAsyncFn(async () => {
    const updates: UserUpdate = {
      uid: user.uid,
      originalId: user.originalId,
      name: `${form.firstName.trim()} ${form.lastName.trim()}`,
      companyName: form.companyName.trim(),
      title: form.title.trim(),
      email: user.email.trim(),
      profilePicture: updateUiAvatarParams(
        form.profilePicture,
        `${form.firstName.trim()} ${form.lastName.trim()}`,
      ),
      updated: true,
      eventId: variant === "live" ? entityId : undefined,
    };

    await Api.UserApi.createUpdate(updates);
    queryClient.invalidateQueries(QueryKeys.user(user.uid));
    initialRef.current = form;
  }, [user.uid, user.originalId, form, user.email]);

  const saveDisabled = !form.firstName || isEqual(initialRef.current, form);
  const userPropName = user.name;

  const inputSX = useMemo(
    () =>
      ({ palette }: Theme) => ({
        backgroundColor: palette.grey[100],
        padding: 0.5,
        paddingLeft: 1,
        paddingRight: 1,
        borderColor: palette.divider,
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 2,
        "&:hover": {
          borderColor: palette.primary.main,
        },
      }),
    [],
  );

  useEffect(() => {
    if (userPropName) {
      setForm((currentForm) => {
        const name = `${currentForm.firstName} ${currentForm.lastName}`;
        if (userPropName === name) {
          return currentForm;
        }

        const formattedUserPropName = splitFullName(userPropName);

        return {
          ...currentForm,
          firstName: formattedUserPropName.firstName,
          lastName: formattedUserPropName.lastName,
        };
      });
    }
  }, [userPropName]);

  return (
    <Container>
      <Typography
        sx={{ paddingBottom: 1, overflowWrap: "break-word", width: "100%" }}
        variant="h3"
      >
        {user.name}
      </Typography>

      <PictureUploadContainer>
        <PictureUpload
          username={user.name}
          currentImage={form.profilePicture}
          imageUploaded={(profilePicture) =>
            setForm((f) => ({
              ...f,
              profilePicture,
            }))
          }
        />
      </PictureUploadContainer>

      <div className="flex w-full gap-4">
        <TextField
          sx={{ marginTop: 1 }}
          fullWidth
          InputProps={{
            disableUnderline: true,
            sx: inputSX,
          }}
          label="First Name"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Enter first name..."
          onChange={(event) => {
            setForm((f) => ({
              ...f,
              firstName: event.target.value,
            }));
          }}
          value={form.firstName}
          error={!form.firstName}
          helperText={!form.firstName ? "First name is required" : null}
        />

        <TextField
          sx={{ marginTop: 1 }}
          fullWidth
          InputProps={{
            disableUnderline: true,
            sx: inputSX,
          }}
          label="Last Name"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Enter last name..."
          onChange={(event) => {
            setForm((f) => ({
              ...f,
              lastName: event.target.value,
            }));
          }}
          value={form.lastName}
        />
      </div>

      <TextField
        sx={{ marginTop: 1 }}
        fullWidth
        InputProps={{
          disableUnderline: true,
          sx: inputSX,
        }}
        label="Company Name"
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="Enter company name..."
        onChange={(event) => {
          setForm((f) => ({
            ...f,
            companyName: event.target.value,
          }));
        }}
        value={form.companyName}
      />

      <TextField
        sx={{ marginTop: 1 }}
        fullWidth
        InputProps={{
          disableUnderline: true,
          sx: inputSX,
        }}
        label="Title"
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="Enter title..."
        onChange={(event) => {
          setForm((f) => ({
            ...f,
            title: event.target.value,
          }));
        }}
        value={form.title}
      />

      <MuiButton
        color="primary"
        variant="contained"
        onClick={onSave}
        disabled={saveDisabled || saveState.loading}
        fullWidth
        sx={{ marginY: 1 }}
        endIcon={
          saveState.loading && <CircularProgress size={20} color="inherit" />
        }
      >
        Save Changes
      </MuiButton>

      <Button
        variant="text"
        onClick={() => setShowLogoutDialog(true)}
        startIcon={<LogoutIcon />}
        data-testid="logout-btn"
        sx={{
          alignSelf: "flex-start",
          ml: 1,
          p: 0.5,
          textAlign: "left",
        }}
      >
        Logout
      </Button>

      {/* logout dialog */}
      <ConfirmationDialog
        open={showLogoutDialog}
        title="Are you sure you want to logout?"
        body="This action will log you out of all your events and clear your profile information."
        confirmText="Logout"
        onConfirm={() => logout({ reload: true })}
        onClose={() => setShowLogoutDialog(false)}
      />
    </Container>
  );
};

export default React.memo(ProfileTab);
